.screen-lock-section {
  background-color: #087392;
  height: 100vh;
  display: flex;
  column-gap: 103px;
  justify-content: center;
  .screen-unlock {
    padding-top: 249px;
    display: flex;
    column-gap: 103px;
    justify-content: center;
  }
  .left-section {
    border-right: 3px solid #ff8e21;
    height: fit-content;
    padding-right: 103px;
    padding-top: 52px;
    .heading-text {
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
  .login-card {
    // width: 380px;
    margin-top: 220px;
    position: relative;
    background: #a4a4a3;
    height: fit-content;
    .login-title {
      color: #fff;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .emoji-img {
      margin-left: 27px;
    }

    .login-thankyou-text {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 85px;
      line-height: 20px; /* 125% */
    }

    .logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 20px;

      .logo {
        width: 150px;
      }
    }

    .password-field {
      margin-bottom: 15px;
    }

    .login-forgot-password-btn-container {
      display: flex;
      justify-content: flex-start;
      margin: 0;
      .ant-btn-link {
        color: #fff;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 200% */
        border-bottom: 1px solid white;
        margin-bottom: 62px;
      }
    }
    .verification-code-container {
      display: flex;
      width: 100%;
      column-gap: 40px;
      .one-digit-input {
        display: flex;
        height: 40px;
        padding: 8px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        color: #087392;
        border-radius: 8px;
        border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
        background: var(--white-100, #fff);
        &:first-child {
          border-left: 1px solid #e2e8f0;
        }

        &:focus {
          outline: none !important;
          border: 1px solid #e2e8f0 !important;
          border-radius: 8px !important;
        }
      }
    }
  }
}
