.collapse-btn {
  background: #eee;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;

  .trigger {
    font-size: 16px;
    transition: color 0.3s;
  }
}

.laststand-logo {
  background: #087392;
  padding: 41px 70px;
  height: 27px;
}

.ant-layout-header {
  box-shadow: 0 4px 2px -2px #ccc;
  z-index: 1;
  overflow: hidden;
}

.site-layout {
  background: #f5f6fa !important;

  .site-layout-background {
    overflow: auto;
  }
}

.bugify-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .merchant-name {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .bell-icon {
    font-size: 20px;
  }
}

.content-main-section {
  position: relative;
  padding: 0;
  margin: 0;
  background: #a4a4a3;
}

.ant-popover-inner-content {
  padding: 5px 10px !important;
}

.user-inner-container {
  .profile-opt {
    width: 150px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    column-gap: 15px;
    font-weight: 500;
  }

  .password-opt {
    width: 150px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    column-gap: 15px;
    font-weight: 500;
  }

  .logout-opt {
    display: flex;
    align-items: center;
    width: 150px;
    padding: 10px 0;
    cursor: pointer;
    column-gap: 15px;
    font-weight: 500;
  }
}

.user-popover {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  .admin-text {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.ant-layout-sider-children {
  position: relative;

  .copyright-text {
    color: #a6b0c2;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}
