.reset-right-section {
  background: #087392;
  padding: 10%;
  height: 100vh;
  display: flex !important;
  align-items: center;

  img {
    width: 100%;
    height: 265px;
    object-fit: contain;
    opacity: 1;
    margin-bottom: 55px;
  }

  .main-tagline {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
  }

  .sub-tagline {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
}

.reset-left-section {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #a4a4a3;
  padding-left: 90px;
  padding-right: 180px;
  .verification-code-container {
    display: flex;
    width: 100%;
    column-gap: 40px;
    .one-digit-input {
      display: flex;
      height: 40px;
      padding: 8px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      color: #087392;
      border-radius: 8px;
      border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
      background: var(--white-100, #fff);
      &:first-child {
        border-left: 1px solid #e2e8f0;
      }

      &:focus {
        outline: none !important;
        border: 1px solid #e2e8f0 !important;
        border-radius: 8px !important;
      }
    }
  }

  .login-card {
    width: 720px;
    position: relative;
    background: #a4a4a3;
    box-shadow: none !important;
    .deposits-input-container {
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.2) !important;
      }
      .ant-select-selector {
        border: none !important;
      }
      height: 40px;
      padding: 0px 0px;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 0px !important;
      border-radius: 8px;
      background: var(--white-100, #fff);
    }
    .ant-form-item-label {
      color: #fff !important;
      padding: 0px;
      font-weight: 400;
      margin-bottom: 17px;
      font-family: Inter;
      font-size: 14px;
      .ant-form-item-required {
        color: #fff !important;
      }
    }
    .ant-radio-inner {
      border-color: #087392;
    }
    .ant-radio-inner::after {
      background-color: #087392 !important;
    }
    .ant-form-item-label {
      label {
        color: #fff;
        padding: 0px;
        font-weight: 400;
        font-family: Inter;
        font-size: 14px;
      }
      .ant-form-item-required {
        color: #fff !important;
      }
    }

    .login-title {
      color: #fff;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .otp-code-text {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .otp-code-text-label {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      margin-bottom: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .forget-thankyou-text {
      font-size: 16px;
      color: white;
      font-family: Inter;
      margin-top: 12px;
      font-weight: 400;
      margin-bottom: 62px;
    }
    .forget-submit-button {
      margin-top: 20px;

      // width: 116px !important;
    }
    .sin-up-text {
      color: #087392;
      cursor: pointer;
    }
    .logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 20px;

      .logo {
        width: 150px;
      }
    }

    .password-field {
      margin-bottom: 15px;
    }

    .forgot-password-btn-container {
      display: flex;
      justify-content: flex-start;
      margin: 0;

      .ant-btn-link {
        color: #fff;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 200% */
        border-bottom: 1px solid white;
        margin-bottom: 62px;
      }
    }

    .ant-btn-lg {
      padding: 4px 30px !important;
    }
  }
  .form-input {
    height: 40px;
    font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--black-20, rgba(0, 0, 0, 0.6));
    padding: 8px 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
    background: var(--white-100, #fff);
    box-shadow: none !important;
    &:hover {
      border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
    }
    &:focus {
      border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
    }
  }
  .dont-have-account {
    color: #fff !important;
    padding: 0px;
    font-weight: 400;
    margin-bottom: 17px;
    font-family: Inter;
    font-size: 15px;
    .sin-up-text {
      color: #087392;
    }
  }

  // .verification-code-container {
  //   display: flex;
  //   width: 100%;
  //   column-gap: 40px;
  //   .one-digit-input {
  //     display: flex;
  //     height: 40px;
  //     padding: 8px 16px;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     flex: 1 0 0;
  //     color: #087392;
  //     border-radius: 8px;
  //     border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
  //     background: var(--white-100, #fff);
  //     &:first-child {
  //       border-left: 1px solid #e2e8f0;
  //     }

  //     &:focus {
  //       outline: none !important;
  //       border: 1px solid #e2e8f0 !important;
  //       border-radius: 8px !important;
  //     }
  //   }
  // }
  .didnot-receive {
    color: #fff;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 200% */
    text-decoration-line: underline;
    cursor: pointer;
  }
}
